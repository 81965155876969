import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appScssStyle]',
  standalone: true,
})
export class ScssStyleDirective {
  @Input() hoverColor: string;
  @Input() borderColor: string;
  private element: HTMLInputElement;

  constructor(private el: ElementRef) {
    this.element = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.hoverColor) {
      this.element.style.setProperty('--hover-color', this.hoverColor);
    }
    if (this.borderColor) {
      this.element.style.setProperty('--border-color', this.borderColor);
    }
  }
}
