import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button-name',
  templateUrl: './button-name.component.html',
  styleUrls: ['./button-name.component.scss'],
})
export class ButtonNameComponent implements OnInit {
  @Input() data: ButtonNameData;
  @Output() iconClick = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}
}

export interface ButtonNameData {
  iconName: string;
  iconPosition: string;
  buttonColor: string;
  name: string;
  iconSize: number;
}
