import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {map, Observable, Subscription} from 'rxjs';
import {Colors, colors, isWindows} from 'src/app/auth/store';
import {ButtonNameData} from './button-name/button-name.component';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() isPrimary: boolean = true;
  @Input() design: ButtonDesign = 'primary';
  @Input() disabled: boolean = false;
  @Input() type: 'submit' | 'button' = 'submit';
  @Input() size: 'small' | 'normal' = 'normal';
  @Input() name: string = 'Button Name';
  @Input() path: string[] = [];
  @Input() iconName: string;
  @Input() iconPosition: 'start' | 'end' = 'start';

  @Output() buttonClick = new EventEmitter();
  @Output() iconClick = new EventEmitter();

  subs: Subscription;

  colors$ = this.store.select(colors);
  isWindows$ = this.store.select(isWindows);

  buttonColor: string;
  backgroundColor: string;
  borderColor: string;
  rippleColor: string;

  constructor(public store: Store) {}

  get buttonStyle() {
    if (this.design === 'gradient') {
      return {
        color: this.buttonColor,
        background: this.backgroundColor,
        border: 'none',
        opacity: this.disabled ? 0.3 : 1,
      };
    }
    return {
      color: this.buttonColor,
      'background-color': this.backgroundColor,
      border: 'solid 2px',
      'border-color': this.borderColor,
      opacity: this.disabled ? 0.3 : 1,
    };
  }

  get buttonNameData$(): Observable<ButtonNameData> {
    return this.isWindows$.pipe(
      map(isWindows => {
        return {
          buttonColor: this.buttonColor,
          iconName: this.iconName,
          name: this.name,
          iconPosition: this.iconPosition,
          iconSize:
            this.size === 'small' ? (isWindows ? 12 : 14) : isWindows ? 14 : 16,
        };
      }),
    );
  }

  ngOnInit(): void {
    this.subs = this.colors$.subscribe(res => {
      this.handleButtonColor(res);
    });
  }

  handleIconClick(event: MouseEvent) {
    event.stopPropagation();
    this.iconClick.emit();
  }

  handleButtonColor(colors: Colors) {
    switch (this.design) {
      case 'primary':
        this.borderColor = colors.primary;
        this.buttonColor = colors.primaryCompliment;
        this.backgroundColor = colors.primary;
        this.rippleColor = colors.rippleCompliment;
        break;
      case 'gradient':
        this.buttonColor = colors.primaryCompliment;
        this.backgroundColor = colors.gradient;
        this.rippleColor = colors.rippleCompliment;
        break;
      case 'gray':
        this.borderColor = colors.secondary4;
        this.buttonColor = colors.secondary1;
        this.backgroundColor = colors.secondary4;
        this.rippleColor = colors.rippleS;
        break;
      case 'secondary':
        this.borderColor = colors.primary;
        this.buttonColor = colors.primary;
        this.backgroundColor = 'transparent';
        this.rippleColor = colors.ripple;
        break;
      case 'PC':
        this.borderColor = colors.primaryCompliment;
        this.buttonColor = colors.primaryCompliment;
        this.backgroundColor = 'transparent';
        this.rippleColor = colors.primaryCompliment;
        break;
      default:
        this.borderColor = '';
        this.buttonColor = '';
        this.backgroundColor = '';
        this.rippleColor = '';
        break;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

export type ButtonDesign = 'primary' | 'secondary' | 'gradient' | 'gray' | 'PC';
