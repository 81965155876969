import {Location} from '@angular/common';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {map} from 'rxjs';
import {Apis} from '../app.apis';
import {appStrings} from '../library/constants/strings';
import {MetaService} from '../library/services/meta.service';
import {colors, devices, error} from './store';
import {setToken} from './store/auth.action';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, AfterViewInit {
  colors$ = this.store.select(colors);
  devices$ = this.store.select(devices);
  error$ = this.store.select(error);
  constructor(
    private store: Store,
    private router: Router,
    private meta: MetaService,
    private location: Location,
  ) {
    this.meta.addPageDetails(appStrings.auth.summary);
  }

  isLoading = false;

  get fontSize$() {
    return this.devices$.pipe(
      map(({isMobile, isTab, isWindows}) => {
        if (isWindows) return '56px';
        if (isMobile) return '28px';
        if (isTab) return '44px';
        return '64px';
      }),
    );
  }

  ngOnInit(): void {
    const splitedUrl = this.router.url.split('#')[1];
    this.location.replaceState(this.location.path().split('#')[0], '');
    if (!splitedUrl) return;
    this.isLoading = true;
    const token = splitedUrl.split('/').pop();
    this.store.dispatch(setToken({payload: token}));
  }

  ngAfterViewInit(): void {
    if (!this.isLoading) this.login();
  }

  login() {
    window.open(Apis.login, '_self');
  }
}
