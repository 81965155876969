import {ActionReducerMap} from '@ngrx/store';
import {AuthState, authReducer} from '../auth/store';

export interface AppState {
  auth: AuthState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: authReducer,
};
