import * as Crypto from 'crypto-js/';

const KEY = 'Iwtgh@6bicntg';

const encryptObj = (obj, pass?: string) => {
  let encryptedStr: string = encrypt(JSON.stringify(obj), pass || KEY);
  return encryptedStr;
};

function decryptObj<T>(str: string, pass?: string) {
  let obj: T | string;
  try {
    obj = JSON.parse(decrypt(str, pass || KEY));
  } catch (error) {
    obj = 'Error';
  }
  return obj;
}

const encrypt = (msg, pass?: string) => {
  var keySize = 256;
  var iterations = 100;
  var salt = Crypto.lib.WordArray.random(128 / 8);

  var key = Crypto.PBKDF2(pass || KEY, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  var iv = Crypto.lib.WordArray.random(128 / 8);
  var value = JSON.stringify(msg);
  var encrypted = Crypto.AES.encrypt(value, key, {
    iv: iv,
    padding: Crypto.pad.Pkcs7,
    mode: Crypto.mode.CBC,
  });

  var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
};

const encryptParam = msg => {
  return encodeURIComponent(encrypt(msg));
};

const decryptParam = msg => {
  return decrypt(decodeURIComponent(msg));
};

const decrypt = (transitmessage, pass?: string) => {
  try {
    var keySize = 256;
    var iterations = 100;
    let salt = Crypto.enc.Hex.parse(transitmessage.substr(0, 32));
    let iv = Crypto.enc.Hex.parse(transitmessage.substr(32, 32));
    let encrypted = transitmessage.substring(64);

    let key = Crypto.PBKDF2(pass || KEY, salt, {
      keySize: keySize / 32,
      iterations: iterations,
    });

    let decrypted = Crypto.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: Crypto.pad.Pkcs7,
      mode: Crypto.mode.CBC,
    });
    return JSON.parse(decrypted.toString(Crypto.enc.Utf8));
  } catch (error) {
    return '';
  }
};

export {encrypt, decrypt, decryptObj, encryptObj, encryptParam, decryptParam};
