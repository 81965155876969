import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Store} from '@ngrx/store';
import {catchError, throwError} from 'rxjs';
import {appStrings} from '../library/constants/strings';
import {DialogService} from '../library/services/dialog.service';
import {logout} from './store/auth.action';

@Injectable()
export class AuthInterceptor {
  private _dialog: DialogService;
  private _injector: Injector;

  constructor(injector: Injector, private store: Store) {
    this._injector = injector;
  }

  private is401(error) {
    return error instanceof HttpErrorResponse && error.status === 401;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(err => {
        if (this.is401(err)) {
          if (!this._dialog) {
            this._dialog = this._injector.get(DialogService);
            const d = this._dialog.error({
              message: appStrings.auth.session,
              button1Name: appStrings.auth.buttons.logout,
            });
            d.afterClosed().subscribe(res => {
              this.store.dispatch(logout());
            });
          }
          return throwError(() => err);
        } else {
          return throwError(() => err);
        }
      }),
    );
  }
}