<div class="flex flex-center">
  <app-icons
    *ngIf="data.iconName && data.iconPosition === 'start'"
    [name]="data.iconName"
    [color]="data.buttonColor"
    [size]="data.iconSize"
  ></app-icons>
  <p
    class="font-clip"
    [class.pl-12]="data.iconName && data.iconPosition === 'start'"
    [class.pr-12]="data.iconName && data.iconPosition === 'end'"
  >
    {{ data.name }}
  </p>
  <div
    *ngIf="data.iconName && data.iconPosition === 'end'"
    (click)="iconClick.emit($event)"
  >
    <app-icons
      [name]="data.iconName"
      [color]="data.buttonColor"
      [size]="data.iconSize"
    ></app-icons>
  </div>
</div>
