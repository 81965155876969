export interface BaseState {
  loading: boolean;
  error: string | Message;
}

export interface IdValueNum {
  id: number;
  value: string;
}
export interface IdValue {
  id: string;
  value: string;
}

export interface IdBoolean {
  id: string;
  value: boolean;
}

export interface IdBooleanNum {
  id: number;
  value: boolean;
}

export type IndicatorType =
  | 'red'
  | 'amber'
  | 'green'
  | 'gray'
  | 'pc'
  | 'escLevel'
  | 'actionLevel';

export enum Indicators {
  RED = 'red',
  AMBER = 'amber',
  GREEN = 'green',
  GRAY = 'gray',
}

export enum PulseIndicators {
  RED = 'Red',
  AMBER = 'Amber',
  GREEN = 'Green',
  GRAY = 'No Client Pulse',
}

export enum StatusIndicators {
  OPEN = 'Open',
  INPROGRESS = 'In Progress',
  CLOSED = 'Closed',
}

export interface TaskData {
  title: string;
  count: number;
  list: string[];
  isExpanded: boolean;
}

export interface FilterData extends IdValueNum {
  isSelceted: boolean;
  count?: number;
}

export interface FilterItem extends IdValueNum {
  isChecked?: boolean;
  count?: number;
  isExpanded?: boolean;
  key?: string;
  accountId?: string;
}

export interface ImpactFilterItem extends IdValue {
  isChecked?: boolean;
  count?: number;
  isExpanded?: boolean;
}

export interface DateRange {
  start: Date;
  end: Date;
  days: number;
}

export interface Message {
  heading: string;
  subtext: string;
  title?: string;
  url?: string;
}

export interface Tab {
  id: number;
  name: string;
  isActive: boolean;
  width?: number;
  postfix?: string;
  type?: string;
  isError?: boolean;
}

export interface TabString {
  id: string;
  name: string;
  isActive: boolean;
  width?: number;
  isError?: boolean;
  postfix?: string;
}

export interface FuteData {
  type: 'eva' | 'screen';
  text: string;
  class?: string;
  clipPath?: string;
  clipPathW?: string;
  styles?: any;
  stylew?: any;
  data?: any;
  list?: string[];
  buttonName: string;
  isCentered: boolean;
}

export interface Tab {
  id: number;
  name: string;
  isActive: boolean;
  width?: number;
  postfix?: string;
  isError?: boolean;
}
