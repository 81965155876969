<button
  *ngIf="path.length > 0; else routerButton"
  matRipple
  [matRippleColor]="rippleColor"
  [matRippleDisabled]="disabled"
  [class.not-allowed]="disabled"
  [type]="type"
  [disabled]="disabled"
  [class]="size === 'small' ? 'font-14-sb' : 'font-16-sb'"
  [ngStyle]="buttonStyle"
  [routerLink]="path"
>
  <app-button-name [data]="buttonNameData$ | async"></app-button-name>
</button>

<ng-template #routerButton>
  <button
    matRipple
    [matRippleColor]="rippleColor"
    [matRippleDisabled]="disabled"
    [class]="size === 'small' ? 'font-14-sb' : 'font-16-sb'"
    [class.not-allowed]="disabled"
    [disabled]="disabled"
    [ngStyle]="buttonStyle"
    [type]="type"
    (click)="buttonClick.emit()"
  >
    <app-button-name
      [data]="buttonNameData$ | async"
      (iconClick)="handleIconClick($event)"
    ></app-button-name>
  </button>
</ng-template>
