import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthState, ColorCSS} from './auth.models';

const selectAuthState = createFeatureSelector<AuthState>('auth');

/* Theme Based Fonts */
export const colors = createSelector(selectAuthState, auth => auth.colors);

export const colorsCSS = createSelector(selectAuthState, auth => {
  const css: ColorCSS = {
    bgP: {'background-color': auth.colors.primary},
    bgP1: {'background-color': auth.colors.primary1},
    bgP2: {'background-color': auth.colors.primary2},
    bgP3: {'background-color': auth.colors.primary3},
    bgP4: {'background-color': auth.colors.primary4},
    bgP5: {'background-color': auth.colors.primary5},
    bgP6: {'background-color': auth.colors.primary6},
    bgPC: {'background-color': auth.colors.primaryCompliment},
    bgPC1: {'background-color': auth.colors.primaryCompliment1},
    bgA: {'background-color': auth.colors.amber},
    bgA1: {'background-color': auth.colors.amber1},
    bgR: {'background-color': auth.colors.red},
    bgR1: {'background-color': auth.colors.red1},
    bgR2: {'background-color': auth.colors.red2},
    bgG: {'background-color': auth.colors.green},
    bgG1: {'background-color': auth.colors.green1},
    bgGR: {background: auth.colors.gradient},
    bgSh: {background: auth.colors.shimmer},
    bgS: {'background-color': auth.colors.secondary},
    bgS1: {'background-color': auth.colors.secondary1},
    bgS2: {'background-color': auth.colors.secondary2},
    bgS3: {'background-color': auth.colors.secondary3},
    bgS4: {'background-color': auth.colors.secondary4},
    bgS5: {'background-color': auth.colors.secondary5},
    bgS6: {'background-color': auth.colors.secondary6},
    bgS7: {'background-color': auth.colors.secondary7},
    bgCardSub: {'background-color': auth.colors.cardSubBg},
    bgT: {'background-color': 'transparent'},
    fontA: {color: auth.colors.amber},
    fontA1: {color: auth.colors.amber1},
    fontG: {color: auth.colors.green},
    fontG1: {color: auth.colors.green1},
    fontP: {color: auth.colors.primary},
    fontP1: {color: auth.colors.primary1},
    fontP2: {color: auth.colors.primary2},
    fontP3: {color: auth.colors.primary3},
    fontP4: {color: auth.colors.primary4},
    fontP5: {color: auth.colors.primary5},
    fontPC: {color: auth.colors.primaryCompliment},
    fontR: {color: auth.colors.red},
    fontR1: {color: auth.colors.red1},
    fontS: {color: auth.colors.secondary},
    fontS1: {color: auth.colors.secondary1},
    fontS2: {color: auth.colors.secondary2},
    fontS3: {color: auth.colors.secondary3},
    fontS4: {color: auth.colors.secondary4},
    fontS5: {color: auth.colors.secondary5},
    fontS6: {color: auth.colors.secondary6},
    fontS7: {color: auth.colors.secondary7},
  };

  return css;
});
