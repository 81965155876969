import {ModuleWithProviders, NgModule} from '@angular/core';

import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {ComponentsModule} from '../library/components/components.module';
import {ScssStyleDirective} from '../library/directives/scss-style.directive';

@NgModule({
  declarations: [AuthComponent],
  imports: [AuthRoutingModule, ComponentsModule, ScssStyleDirective],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
    };
  }
}
