<div class="background-image">
  <span
    *ngIf="isLoading && !(error$ | async)"
    class="loader"
    appScssStyle
    [borderColor]="(colors$ | async).primary"
    [hoverColor]="(colors$ | async).secondary3"
  ></span>
  <p
    class="font-28-b pb-24"
    [style.fontSize]="fontSize$ | async"
    [style.color]="(colors$ | async).secondary3"
  >
    Welcome to CodeX
  </p>
  <p
    class="font-16-i pb-24"
    [style.color]="(colors$ | async).red"
    *ngIf="error$ | async"
  >
    {{ error$ | async }}
  </p>
  <app-button
    *ngIf="!isLoading"
    name="Sign in to your account"
    design="gradient"
    (buttonClick)="login()"
  ></app-button>
</div>
