import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {colors} from 'src/app/auth/store';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() title = '';
  @Input() url = '';
  @Input() target: '_blank' | '_self' = '_blank';
  @Input() font = 'font-16-sb';
  @Input() path: string;
  @Input() iconName: string;
  @Input() iconSize: number;
  @Input() disabled = false;

  @Output() linkClick = new EventEmitter();

  colors$ = this.store.select(colors);

  constructor(private store: Store, private router: Router) {}

  handleClick() {
    if (this.disabled) return;
    if (this.url) window.open(this.url, this.target);
    if (this.path) this.router.navigateByUrl(this.path);
    else this.linkClick.emit();
  }
}
