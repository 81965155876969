import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentsModule } from '../../components/components.module';
import { Store } from '@ngrx/store';
import { colors } from 'src/app/auth/store';
import { ButtonDesign } from '../../components/button/button.component';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [CommonModule, ComponentsModule],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  colors$ = this.store.select(colors);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ErrorData,
    private store: Store,
  ) { }

  ngOnInit(): void { }
}

export interface ErrorData {
  message: string;
  button1Name?: string;
  button2Name?: string;
  button2Type?: ButtonDesign;
  button3Name?: string;
  dismissData?: any;
}
