import {Component, HostListener, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {environment} from 'src/environments/environment';
import {autoLogin, setDeviceWidth} from './auth/store/auth.action';

import {MyMonitoringService} from './service/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  version = environment.appVersion;

  constructor(
    private store: Store,
    private monitoringService: MyMonitoringService,
  ) {}

  @HostListener('window:resize') onResize() {
    this.store.dispatch(setDeviceWidth({payload: window.innerWidth}));
  }

  ngOnInit(): void {
    this.store.dispatch(setDeviceWidth({payload: window.innerWidth}));
    this.store.dispatch(autoLogin());
  }
}
