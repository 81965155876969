import {createReducer, on} from '@ngrx/store';
import {AuthState} from './auth.models';
import * as AuthActions from './auth.action';
import {defaultColors, userDefault} from './auth.constants';
import {v4 as uuidv4} from 'uuid';

const initialState: AuthState = {
  error: null,
  loading: false,
  user: null,
  isMobile: false,
  isTab: false,
  isWindows: false,
  colors: defaultColors,
  snacks: [],
  badgeInfo: null,
  accountRoles: [],
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.setDeviceWidth, (state, {payload}) => {
    return {
      ...state,
      isTab: payload >= 500 && payload < 1199,
      isMobile: payload < 500,
      isWindows: payload > 1199 && payload < 1600,
    };
  }),
  on(AuthActions.setToken, (state, {payload}) => {
    return {...state, user: {...userDefault, token: payload}};
  }),
  on(AuthActions.setUserDetails, (state, {payload}) => {
    return {...state, user: payload};
  }),
  on(AuthActions.addSnackItem, (state, {payload}) => {
    return {...state, snacks: [{...payload, id: uuidv4()}, ...state.snacks]};
  }),
  on(AuthActions.removeSnackItem, (state, {payload}) => {
    return {
      ...state,
      snacks: state.snacks.filter(snack => snack.id !== payload),
    };
  }),
  on(AuthActions.setBadgeInfo, (state, {payload}) => {
    return {...state, badgeInfo: payload};
  }),
  on(AuthActions.setUserDetailsErr, (state, {payload}) => {
    return {...state, error: payload};
  }),
  on(AuthActions.setCodexIdsErr, (state, {payload}) => {
    return {...state, error: payload};
  }),

  on(AuthActions.setAccountRoles, (state, {payload}) => {
    return {
      ...state,
      accountRoles: payload,
    };
  }),
);
