import { environment as baseUrls } from 'src/environments/environment';

export const Apis = {
  login: `${baseUrls.ums}/CodeX-UMS/OAuth/request?env=${baseUrls.platform}`,
  logout: `${baseUrls.ums}/CodeX-UMS/sign_out`,
  logoutRedirect: `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=`,
  getUserDetails: `${baseUrls.ums}/CodeX-UMS/retrieveSession`,
  getDashbaordCount: `${baseUrls.ums}/CodeX-A-WSR/dashboardSummaryCount`,
  getDashbaordCountById: `${baseUrls.ums}/CodeX-A-WSR/dashboardSummaryCountbyId`,
  getProjects: `${baseUrls.ums}/CodeX-UMS/listprojects`,
  getProjectById: `${baseUrls.ums}/CodeX-A-WSR/projectsbycIds`,
  getClientPulseById: `${baseUrls.ums}/CodeX-A-WSR/getpulsebyCodexId`,
  getProjectFeaturesById: `${baseUrls.ums}/CodeX-A-WSR/getTabItems`,
  getProjectRAIDById: `${baseUrls.ums}/CodeX-A-WSR/getRaidData`,
  getBadgeInfo: `${baseUrls.ums}/CodeX-UMS/getBadgeInfo`,
  getCodexIds: `${baseUrls.ums}/CodeX-UMS/getFeturesByCodexIds`,
  getUserRole: `${baseUrls.ums}/CodeX-UMS/getUserDetails`,
  getAllActions: `${baseUrls.ums}/CodeX-A-WSR/getAllActionsView`,
  getCsatData: `${baseUrls.ums}/CodeX-A-WSR/getActionAdditionalFields`,
  getAccountActions: `${baseUrls.ums}/CodeX-A-WSR/getAccActionsView`,
  getAllSelectedUsers: `${baseUrls.ums}/CodeX-UMS/getAllSelectedUsers`,
  createAction: `${baseUrls.ums}/CodeX-A-WSR/createAction`,
  updateAction: `${baseUrls.ums}/CodeX-A-WSR/updateAction`,
  updateActions: `${baseUrls.ums}/CodeX-A-WSR/updateActions`,
  getEscalations: `${baseUrls.ums}/CodeX-A-WSR/getEscalationsViews`,
  getRisks: `${baseUrls.ums}/CodeX-A-WSR/getAllRisksForDashboardTiles/-2`,
  getUnlinkedProjects: `${baseUrls.ums}/Codex-PG-MGT/getNetSuitProjects `,
  createRisk: `${baseUrls.ums}/CodeX-A-WSR/createRisk`,
  updateRisk: `${baseUrls.ums}/CodeX-A-WSR/updateRisk`,
  convertRisk: `${baseUrls.ums}/CodeX-A-WSR/createInternalIssuefromRisk`,
  getActionsById: `${baseUrls.ums}/CodeX-A-WSR/getActionsById`,
  createEscaltion: `${baseUrls.ums}/CodeX-A-WSR/createEscalations/Escalation`,
  getCodexProjects: `${baseUrls.ums}/Codex-PG-MGT/getCodexProjects `,
  createCodexProject: `${baseUrls.ums}/Codex-PG-MGT/createCodexProject`,
  // getAllInternalIssue: `${baseUrls.ums}/CodeX-A-WSR/getEscalationsViews`,
  getAllInternalIssue: `${baseUrls.ums}/CodeX-A-WSR/getEscalationsViews`,
  getAllAccountInternalIssue: `${baseUrls.ums}/CodeX-A-WSR/getAllAccIssue`,
  updateCodexProject: `${baseUrls.ums}/Codex-PG-MGT/editCodexProject`,
  createNewInternalIssue: `${baseUrls.ums}/CodeX-A-WSR/createEscalations/Issue`,
  updateInternalIssue: `${baseUrls.ums}/CodeX-A-WSR/updateEscalation/Issue`,
  updateEscalation: `${baseUrls.ums}/CodeX-A-WSR/updateEscalation/Escalation`,
  getAccountPractice: `${baseUrls.ums}/CodeX-A-WSR/getAccountPractice`,
  getProjectNameAndCodeId: `${baseUrls.ums}/CodeX-A-WSR/getProjectsUsingAccount`,
  getDependency: `${baseUrls.ums}/CodeX-A-WSR/getDependency`,
  getAssumptionByCid: `${baseUrls.ums}/CodeX-A-WSR/getAllAssumptions`,
  createAssumptionByCid: `${baseUrls.ums}/CodeX-A-WSR/createAssumption`,
  updateAssumptionByCid: `${baseUrls.ums}/CodeX-A-WSR/updateAssumption`,
  getStakeHoldersByCid: `${baseUrls.ums}/CodeX-A-WSR/getStakeholdersByCid`,
  getStatusReportList: `${baseUrls.ums}/CodeX-A-WSR/getWsrByCodexId/Status%20report`,
  getRiskById: `${baseUrls.ums}/CodeX-A-WSR/getAllOpenRiskByCodexId`,
  getAllActionsById: `${baseUrls.ums}/CodeX-A-WSR/getAllOpenActionsbyCodexId`,
  getCodexIdforActionByUserId: `${baseUrls.ums}/CodeX-A-WSR/getCodexIdforActionByUserId`,
  getIssuesById: `${baseUrls.ums}/CodeX-A-WSR/getOpenEscalations`,
  createDependency: `${baseUrls.ums}/CodeX-A-WSR/createDependency`,
  updateDependency: `${baseUrls.ums}/CodeX-A-WSR/updateDependency`,
  getDraftStatusReportList: `${baseUrls.ums}/CodeX-A-WSR/getDraftedReport/{#{#}#}/Status%20Report`,
  getCustomerMailList: `${baseUrls.ums}/CodeX-A-WSR/getWsrByCodexId/Customer%20Mail`,
  getDraftCustomerMailList: `${baseUrls.ums}/CodeX-A-WSR/getDraftedReport/{#{#}#}/Customer%20Mail`,
  getCustomerFeedbackList: `${baseUrls.ums}/CodeX-A-WSR/getCustomerFeedbackByGroupIdsUsingInQuery`,
  getActionsForEscalation: `${baseUrls.ums}/CodeX-A-WSR/getActionsById/1`,
  getAllOpenActionsForAssumptions: `${baseUrls.ums}/CodeX-A-WSR/getAllOpenActionsForAssumptions`,
  getActionsForRisk: `${baseUrls.ums}/CodeX-A-WSR/getActionsById/2`,
  createRCA: `${baseUrls.ums}/CodeX-A-WSR/addRCA`,
  getSpocsById: `${baseUrls.ums}/CodeX-A-WSR/getSpocsById`,
  getNetSuiteSpocsById: `${baseUrls.ums}/CodeX-A-WSR/getNetSuiteSpocsById`,
  createSpoc: `${baseUrls.ums}/CodeX-A-WSR/createSpoc`,
  updateSpoc: `${baseUrls.ums}/CodeX-A-WSR/updateSpoc`,
  deleteSpoc: `${baseUrls.ums}/CodeX-A-WSR/deleteSpoc`,
  projectHealthEscalation: `${baseUrls.ums}/CodeX-A-WSR/getNewProjectHealthEscalations`,
  projectHealthHistory: `${baseUrls.ums}/CodeX-A-WSR/getAllProjectHealth`,
  projectHealthDashboardCounts: `${baseUrls.ums}/CodeX-A-WSR/getAllDasboardCounts`,
  getProjectConfigurationDetails: `${baseUrls.ums}/Codex-PG-MGT/getProjectConfigurationDeatils`,
  getOpenEscalationCount: `${baseUrls.ums}/CodeX-A-WSR/getOpenEscalationCount`,
  deleteDraftReport: `${baseUrls.ums}/CodeX-A-WSR/deleteDraft`,
  getAllOpenActionsbyCodex: `${baseUrls.ums}/CodeX-A-WSR/getAllOpenActionsbyCodexId`,
  submitUpdateProjectConfiguration: `${baseUrls.ums}/Codex-PG-MGT/updateProjectConfig`,
  getCommentsById: `${baseUrls.ums}/CodeX-A-WSR/getCommentsByActionId`,
  addComment: `${baseUrls.ums}/CodeX-A-WSR/createComment`,
  getProjectConfig: `${baseUrls.ums}/Codex-PG-MGT/getProjectConfigurationDeatils`,
  getPrevHealthStatus: `${baseUrls.ums}/CodeX-A-WSR/previousProjectStatus`,
  getSpocksById: `${baseUrls.ums}/CodeX-A-WSR/getSpocsById`,
  submitWSR: `${baseUrls.ums}/CodeX-A-WSR/submitWsr/{#{#}#}/123/123`,
  getWSR: `${baseUrls.ums}/CodeX-A-WSR/getwsr`,
  downloadWSRFile: `${baseUrls.ums}/CodeX-A-WSR/downloadWsrFile`,
  powerbiDashboard: `${baseUrls.ums}/CodeX-UMS/getPowerbiDashboard`,
  getSourceData: `${baseUrls.ums}/CodeX-A-WSR/getSourceDetails`,
  getCpAndPhByUserIds: `${baseUrls.ums}/CodeX-UMS/getCpAndPhByUserIds`,
  getRiskProfileData: `${baseUrls.ums}/CodeX-A-WSR/getRiskProfile/`,
  getAllHelpFiles: `${baseUrls.ums}/CodeX-UMS//getAllHelpFiles`,
  getLatestFeatures: `${baseUrls.ums}/CodeX-UMS/getLatestFeatures`,
  saveRiskProfile: `${baseUrls.ums}/CodeX-A-WSR/saveRiskProfile`,
  updateProjectHealth: `${baseUrls.ums}/CodeX-A-WSR/saveProjectHealth?flagCreatInternalIssue=true`,
  updateProactiveProjectHealth: `${baseUrls.ums}/CodeX-A-WSR/saveProactiveProjectHealth`,
  peopleSearch: `${baseUrls.ums}/CodeX-UMS/userDetailList`,
  getSidePanel: `${baseUrls.ums}/CodeX-UMS/getsidepanel`,
  projectInformationExist: `${baseUrls.ums}/Codex-PG-MGT/createProjectInfomationIfNotExist`,
  getPIDescription: `${baseUrls.ums}/Codex-PG-MGT/getPIDescription`,
  getPIAcceptanceCriteria: `${baseUrls.ums}/Codex-PG-MGT/getPIAcceptanceCriteria`,
  getAllProjectAttributeDomain: `${baseUrls.ums}/Codex-PG-MGT/getAllProjectAttributeDomain`,
  getMaxUsageOfToolsAndTechnology: `${baseUrls.ums}/Codex-PG-MGT/getMaxUsageOfToolsAndTechnology`,
  saveUpdatePIDescription: `${baseUrls.ums}/Codex-PG-MGT/saveUpdatePIDescription?KeepNRecords=3`,
  saveUpdatePIAcceptanceCriteria: `${baseUrls.ums}/Codex-PG-MGT/saveUpdatePIAcceptanceCriteria?KeepNRecords=3`,
  updateProjectConfig: `${baseUrls.ums}/Codex-PG-MGT/updateProjectConfig`,
  createAndGetProjectAttributeIfNotExist: `${baseUrls.ums}/Codex-PG-MGT/createAndGetProjectAttributeIfNotExist`,
  getProjectAttributeDomain: `${baseUrls.ums}/Codex-PG-MGT/getProjectAttributeDomain`,
  piAttrToolTechnologiesByAtributeId: `${baseUrls.ums}/Codex-PG-MGT/piAttrToolTechnologiesByAtributeId`,
  deletePIToolandTechologyById: `${baseUrls.ums}/Codex-PG-MGT/deletePIToolandTechologyById`,
  deleteProjectAttributeDomainById: `${baseUrls.ums}/Codex-PG-MGT/deleteProjectAttributeDomainById`,
  saveProjectAttributeDomain: `${baseUrls.ums}/Codex-PG-MGT/saveProjectAttributeDomain`,
  getProjectConfigurationDeatils: `${baseUrls.ums}/Codex-PG-MGT/getProjectConfigurationDeatils`,
  saveToolsandTechnologyDomain: `${baseUrls.ums}/Codex-PG-MGT/saveToolsTechnology`,
  getAssessments: `${baseUrls.ums}/CodeX-A-WSR/getreviews`,
  getAssessCriterias: `${baseUrls.ums}/CodeX-A-WSR/getassesscriterias`,
  viewAssesments: `${baseUrls.ums}/CodeX-A-WSR/viewassesments`,
  createAssessment: `${baseUrls.ums}/CodeX-A-WSR/createassesments`,
  updateAssessment: `${baseUrls.ums}/CodeX-A-WSR/updateassesment`,
  pendingAssessments: `${baseUrls.ums}/CodeX-A-WSR/pendingAssessments`,
  pendingDraftAssessments: `${baseUrls.ums}/CodeX-A-WSR/pendingAssessmentsDraftsIncluded`,
  assessmentWindow: `${baseUrls.ums}/CodeX-A-WSR//isAssessmentWindow`,
  getAllProjectInformation: `${baseUrls.ums}/Codex-PG-MGT/getAllProjectInformation`,
  getRiskProfileDetails: `${baseUrls.ums}/CodeX-A-WSR/getRiskProfile`,
  getProjectAssetsByCid: `${baseUrls.ums}/CodeX-A-WSR/getProjectAssetsByCidForPA`,
  getAccountsForCP: `${baseUrls.ums}/CodeXSentiments/getAccountsForCP`,
  getTimelinesDataByAccount: `${baseUrls.ums}/CodeXSentiments/getTimelinesDataByAccount`,
  getCodexSentimentTableData: `${baseUrls.ums}/CodeXSentiments/getCodexSentimentTableData`,
  getAccessToken: `https://codex.brillio.com/CodeX-Integration/getAccessToken`,
  getInternalIssueForTelemetry: `https://codex.brillio.com/CodeX-Integration/getAllInternalIssue`,
  getProjectConfigurationSummary: `https://codex.brillio.com/CodeX-Integration/getProjectConfigurationSummary`,
  getRiskSummary: `https://codex.brillio.com/CodeX-Integration/getNewRiskSummary`,
  getAllDependency: `https://codex.brillio.com/CodeX-Integration/getAllDependency`,
  getAllAssumptions: `https://codex.brillio.com/CodeX-Integration/getAllAssumptions`,
  getAllOpenProjectSummary: `https://codex.brillio.com/CodeX-Integration/getAllOpenProjectSummary`,
  getClientPulseTelemetry: `https://codex.brillio.com/CodeX-Integration/getClientPulseSummaryReport`,
  getRAGTelemetry: `https://codex.brillio.com//CodeX-Integration/getAllRagSummary`,
  getEscalationTelemetry: `https://codex.brillio.com/CodeX-Integration/getAllEscalationSummary`,
  getWSRTelemetry: `https://codex.brillio.com/CodeX-Integration/getAllWsrSummary`,
  getSPOCTelemetry:
    'https://codex.brillio.com//CodeX-Integration/getSpocSummary',
  getProjectMasterTelemetry: `https://codex.brillio.com/CodeX-Integration/getProjectMasterSummary`,
  getActionTelemetry: `https://codex.brillio.com/CodeX-Integration/getAllActionSummary`,
  getAllProjectsTelemetry: `https://codex.brillio.com/CodeX-Integration/getAllProjectSummary`,
  getUsageSummary: `https://codex.brillio.com/CodeX-Integration/getCodexUsageSummary`,
  getUsageSummaryStage: `https://codex.brillio.com/CodeX-Integration/getCodexUsageSummary`,
  getClientPulseByCodeXGroupID: `${baseUrls.ums}/CodeX-A-WSR/getClientPulseByCodeXGroupID`,
  getProjectCalendarGovernanceByCodexId: `${baseUrls.ums}/CodeX-A-WSR/getProjectCalendarGovernanceByCodexId`,
  saveReviewers: `${baseUrls.ums}/Codex-PG-MGT/saveReviewers`,
  getIppByCodexId: `${baseUrls.ums}/Codex-PG-MGT/getIppByCodexId`,
  getIppComments: `${baseUrls.ums}/Codex-PG-MGT/getCommentsbyIppId`,
  saveIppComment: `${baseUrls.ums}/Codex-PG-MGT/saveIppcomment`,
  saveMasterToolPurpose: `${baseUrls.ums}/Codex-PG-MGT/saveMasterToolPurpose`,
  saveEstimationValue: `${baseUrls.ums}/Codex-PG-MGT/saveEstimationValue`,
  saveDeCoach: `${baseUrls.ums}/Codex-PG-MGT/saveDeCoach`,
  saveActualStartDate: `${baseUrls.ums}/Codex-PG-MGT/saveActualStartDate`,
  saveSolidMargin: `${baseUrls.ums}/Codex-PG-MGT/saveSolidMargin`,
  publishProjectInformation: `${baseUrls.ums}/Codex-PG-MGT/publishProjectInformation`,
  getProjectAssets: `${baseUrls.ums}/CodeX-A-WSR/getProjectAssetsByCodexId`,
  downloadProjectAssets: `${baseUrls.ums}/CodeX-A-WSR/downLoadProjectAssets`,
  deleteProjectAssetsById: `${baseUrls.ums}/CodeX-A-WSR/deleteProjectAssetsById`,
  getCountArchivedFilesByCodexId: `${baseUrls.ums}/CodeX-A-WSR/getCountArchivedFilesByCodexId`,
  saveProjectAssets: `${baseUrls.ums}/CodeX-A-WSR/saveProjectAssets`,
  submitFeedback: `${baseUrls.ums}/CodeX-A-WSR/setClientPulseComment`,
  updateHealthOnTransaction: `${baseUrls.ums}/CodeX-A-WSR/updateHealthOnTransaction`,
  getClientFeedBack: `${baseUrls.ums}/CodeX-A-WSR/getClientFeedBack`,
  getClientPulse: `${baseUrls.ums}/CodeX-A-WSR/getClientPulse`,
  updateRagBasedOnClientPulse: `${baseUrls.ums}/CodeX-A-WSR/updateRagBasedOnClientPulse`,
  createIfNotExist: `${baseUrls.ums}/Codex-PG-MGT/createIfNotExist`,
  syncListProjectIntoCache: `${baseUrls.ums}/CodeX-UMS/syncProjectListLatestIntoCache`,
  getAllHelpFilesFormatted: `${baseUrls.ums}/CodeX-UMS/getAllHelpFilesFormatted`,
  isRCAForAccountEscalation: `${baseUrls.ums}/CodeX-A-WSR/isRCAForAccountEscalation`,
  getAccounts: `${baseUrls.ums}/CodeX-UMS/getAllAccounts?filter=`,
  getAccountCount: `${baseUrls.ums}/CodeX-UMS/getAccountSummaryCount`,
  getAccountDetails: `${baseUrls.ums}/CodeX-UMS/getAllAccounts?filter=0&accountID=`,
  getAccountRaidDetails: `${baseUrls.ums}/CodeX-A-WSR/getRaidAccountData/`,
  getAccountPulse: `${baseUrls.ums}/CodeX-A-WSR/getpulsebyAccountId/`,
  getAccountFeatures: `${baseUrls.ums}/CodeX-A-WSR/getAccountTabItems/`,
  updateBlobForClientPulse: `https://codex.brillio.com/CodeX-ClientFeedback/clientFeedbackerror`,
  getUserAccountWithRoleIds: `${baseUrls.ums}/CodeX-UMS/getUserAccountWithRoleIds`,
  getAccountListCount: `${baseUrls.ums}/CodeX-UMS/getAccountCount`,
  getCxsurveyQuestions: `${baseUrls.ums}/CodeX-A-WSR/getAllQues`,
  getAllCxSurveys: `${baseUrls.ums}/CodeX-A-WSR/getAllCXSurveys`,
  createCxSurvey: `${baseUrls.ums}/CodeX-A-WSR/processCxSurvey`,
  getQAForSurvey: `${baseUrls.ums}/CodeX-A-WSR/api/feedback/getQuestions`,
  saveFeedback: `${baseUrls.ums}/CodeX-A-WSR/api/feedback/saveFeedback`,
  getQuestionsByWsrId: `${baseUrls.ums}/CodeX-A-WSR/getQAForSurvey`,
  getFeedbackDetailsByFeedbackId: `${baseUrls.ums}/CodeX-A-WSR/getFeedbackDetails`,
  getFeedbackDetailsByWsrId: `${baseUrls.ums}/CodeX-A-WSR/getFeedbacksBySurvey`,
  getAllArchetypes: `${baseUrls.ums}/Codex-PG-MGT/getAllArchetypes`
};
