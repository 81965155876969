import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';

import {ButtonComponent} from './button/button.component';
import {IconsComponent} from './icons/icons.component';
import {HttpClientModule} from '@angular/common/http';
import {LinkComponent} from './link/link.component';
import {ButtonNameComponent} from './button/button-name/button-name.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    ButtonComponent,
    IconsComponent,
    LinkComponent,
    ButtonNameComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    HttpClientModule,
    MatRippleModule,
    MatDialogModule,
    FormsModule,
  ],
  exports: [
    // Modules
    CommonModule,
    // Components
    ButtonComponent,
    IconsComponent,
    LinkComponent,
    MatDialogModule,
    FormsModule,
  ],
})
export class ComponentsModule {}
