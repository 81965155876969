import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {StoreModule} from '@ngrx/store';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {appReducer} from './store/app.reducer';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AsyncPipe} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {appEffects} from './store/app.effects';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptor';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from 'src/environments/environment.prod';
import {QuillModule} from 'ngx-quill';
import {MyMonitoringService} from './service/logging.service';
import {ErrorHandlerService} from './service/errorHandlerService.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AsyncPipe,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot(),
    QuillModule.forRoot(),
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot(appEffects),
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    MyMonitoringService,
    {provide: ErrorHandler, useClass: ErrorHandlerService},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
