import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {colors} from 'src/app/auth/store';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Message} from 'src/app/app.models';
import {ComponentsModule} from '../../components/components.module';

@Component({
  selector: 'app-coming-soon',
  standalone: true,
  imports: [CommonModule, ComponentsModule],
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent implements OnInit {
  colors$ = this.store.select(colors);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Message,
    private store: Store,
  ) {}

  ngOnInit(): void {}
}
