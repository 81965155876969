import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class HttpService {
  constructor(private http: HttpClient) {}

  get<T>(api: string, token?: string, params?: any) {
    const headers = token ? {token} : {};
    return this.http.get<T>(api, {headers, params});
  }

  getBearer<T>(api: string, token?: string, params?: any) {
    const headers = token ? {Authorization: `Bearer ${token}`} : {};
    return this.http.get<T>(api, {headers, params});
  }

  postBearer<T>(api: string, token?: string, data?: any, params?: any) {
    const headers = token ? {Authorization: `Bearer ${token}`} : {};
    return this.http.post<T>(api, data, {headers, params});
  }

  download<T>(api: string, token?: string, params?: any) {
    const headers = token ? {token} : {};
    return this.http.get<T>(api, {
      headers,
      params,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  post<T>(api: string, token?: string, data?: any, params?: any) {
    const headers = token ? {token} : {};
    return this.http.post<T>(api, data, {headers, params});
  }

  delete<T>(api: string, token?: string, params?: any) {
    const headers = token ? {token} : {};
    return this.http.delete<T>(api, {headers, params});
  }

  put<T>(api: string, token?: string, data?: any, params?: any) {
    const headers = token ? {token} : {};
    return this.http.put<T>(api, data, {headers, params});
  }
}

export interface DefaultReponse {
  status: boolean;
  message: string;
}
