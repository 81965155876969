import {FilterData} from 'src/app/app.models';

export const quickFilterList: FilterData[] = [
  {id: 1, value: 'Select All', count: 0, isSelceted: true},
  {id: 2, value: 'Open', count: 0, isSelceted: false},
  {id: 7, value: 'Project Level', count: 0, isSelceted: false},
  {id: 8, value: 'Account Level', count: 0, isSelceted: false},
  {id: 4, value: 'CSAT', count: 0, isSelceted: false},
  {id: 9, value: 'RightStart', count: 0, isSelceted: false},
  {id: 3, value: 'Escalation', count: 0, isSelceted: false},
  // {id: 4, value: 'Governance Actions', count: 0, isSelceted: false},
  {id: 5, value: 'Risk', count: 0, isSelceted: false},
  // {id: 6, value: 'Secondary', count: 0, isSelceted: false},
];

export const filterData: FilterData[] = [
  {id: 1, count: 0, isSelceted: true, value: 'Source'},
  {id: 2, count: 0, isSelceted: false, value: 'Status'},
  {id: 3, count: 0, isSelceted: false, value: 'Account'},
  {id: 4, count: 0, isSelceted: false, value: 'Assigned To'},
  {id: 5, count: 0, isSelceted: false, value: 'Raised By'},
  {id: 6, count: 0, isSelceted: false, value: 'Level'},
  {id: 7, count: 0, isSelceted: false, value: 'Action Type'},
];

export const actionStatus: {[s: number]: string} = {
  0: 'Open',
  1: 'In Progress',
  2: 'Closed',
};

export const actionSource: {[s: number]: string} = {
  1: 'Escalation',
  2: 'Risk',
  3: 'Project Actions',
  5: 'Internal Issue',
  7: 'Governance',
  8: 'Project Self Assessment',
  11: 'Dependency',
  12: 'Assumptions',
  13: 'Account Actions',
};
