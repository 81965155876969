export const paths = {
  add: {path: 'assets/icons/add-white.svg', name: 'add'},
  menu: {path: 'assets/icons/menu.svg', name: 'menu'},
  remove: {path: 'assets/icons/remove.svg', name: 'remove'},
  cardView: {path: 'assets/icons/card-view.svg', name: 'card-view'},
  listView: {path: 'assets/icons/list-view.svg', name: 'list-view'},
  eva: {path: 'assets/icons/eva.svg', name: 'eva'},
  bell: {path: 'assets/icons/bell.svg', name: 'bell'},
  search: {path: 'assets/icons/search.svg', name: 'search'},
  filter: {path: 'assets/icons/filter.svg', name: 'filter'},
  happy: {path: 'assets/icons/happy.svg', name: 'happy'},
  okay: {path: 'assets/icons/okay.svg', name: 'okay'},
  sad: {path: 'assets/icons/sad.svg', name: 'sad'},
  health: {path: 'assets/icons/health.svg', name: 'health'},
  goTo: {path: 'assets/icons/go-to.svg', name: 'go-to'},
  person: {path: 'assets/icons/person.svg', name: 'person'},
  more: {path: 'assets/icons/more.svg', name: 'more'},
  rca: {path: 'assets/icons/rca.svg', name: 'rca'},
  edit: {path: 'assets/icons/edit.svg', name: 'edit'},
  close: {path: 'assets/icons/close.svg', name: 'close'},
  arrowDown: {path: 'assets/icons/arrow-down.svg', name: 'arrow-down'},
  arrowUp: {path: 'assets/icons/arrow-up.svg', name: 'arrow-up'},
  arrowRight: {path: 'assets/icons/arrow-right.svg', name: 'arrow-right'},
  codex: {path: 'assets/icons/codex.svg', name: 'codex'},
  profile: {path: 'assets/icons/profile.svg', name: 'profile'},
  minus: {path: 'assets/icons/minus.svg', name: 'minus'},
  task: {path: 'assets/icons/task.svg', name: 'task'},
  ribbon: {path: 'assets/icons/ribbon.svg', name: 'ribbon'},
  ribbon1: {path: 'assets/icons/ribbon1.svg', name: 'ribbon1'},
  ribbon2: {path: 'assets/icons/ribbon2.svg', name: 'ribbon2'},
  rightArrow: {path: 'assets/icons/right-arrow.svg', name: 'right-arrow'},
  risk: {path: 'assets/icons/risk.svg', name: 'risk'},
  assumption: {path: 'assets/icons/assumption.svg', name: 'assumption'},
  internalIssue: {
    path: 'assets/icons/internal-issue.svg',
    name: 'internal-issue',
  },
  action: {path: 'assets/icons/action.svg', name: 'action'},
  dependency: {path: 'assets/icons/dependency.svg', name: 'dependency'},
  escalations: {path: 'assets/icons/escalations.svg', name: 'escalations'},
  clientPulse: {path: 'assets/icons/client-pulse.svg', name: 'client-pulse'},
  statusReport: {path: 'assets/icons/status-report.svg', name: 'status-report'},
  customerEmail: {
    path: 'assets/icons/customer-email.svg',
    name: 'customer-email',
  },
  Assessment: {
    path: 'assets/icons/monthly-assessment.svg',
    name: 'monthly-assessment',
  },
  riskProfile: {path: 'assets/icons/risk-profile.svg', name: 'risk-profile'},
  revenueMargin: {
    path: 'assets/icons/revenue-margin.svg',
    name: 'revenue-margin',
  },
  projectGovernance: {
    path: 'assets/icons/project-governance.svg',
    name: 'project-governance',
  },
  projectInfo: {path: 'assets/icons/project-info.svg', name: 'project-info'},
  stakeholder: {path: 'assets/icons/stakeholder.svg', name: 'stakeholder'},
  projectConfig: {
    path: 'assets/icons/project-config.svg',
    name: 'project-config',
  },
  projectPlan: {path: 'assets/icons/project-plan.svg', name: 'project-plan'},
  projectAssets: {
    path: 'assets/icons/project-assets.svg',
    name: 'project-assets',
  },
  alert: {path: 'assets/icons/alert.svg', name: 'alert'},
  collaps: {path: 'assets/icons/collaps.svg', name: 'collaps'},
  comment: {path: 'assets/icons/comment.svg', name: 'comment'},
  uncheck: {path: 'assets/icons/uncheck.svg', name: 'uncheck'},
  check: {path: 'assets/icons/check.svg', name: 'check'},
  date: {path: 'assets/icons/date.svg', name: 'date'},
  delete: {path: 'assets/icons/delete.svg', name: 'delete'},
  tick: {path: 'assets/icons/tick.svg', name: 'tick'},
  email: {path: 'assets/icons/email.svg', name: 'email'},
  download: {path: 'assets/icons/download.svg', name: 'download'},
  uploadFile: {path: 'assets/icons/upload-file.svg', name: 'upload-file'},
  file: {path: 'assets/icons/file.svg', name: 'file'},
  folder: {path: 'assets/icons/folder.svg', name: 'folder'},
  reload: {path: 'assets/icons/reload.svg', name: 'reload'},
  tripleArrow: {path: 'assets/icons/triple-arrow.svg', name: 'triplearrow'},
  projectSearch: {
    path: 'assets/icons/project-search.svg',
    name: 'project-search',
  },
  brillio: {path: 'assets/icons/brillio.svg', name: 'brillio'},
  target: {path: 'assets/icons/target.svg', name: 'target'},
  riskprofileicon: {
    path: 'assets/icons/riskprofileicon.svg',
    name: 'riskprofileicon',
  },
  rightarrowicon: {
    path: 'assets/icons/rightarrow.svg',
    name: 'rightarrowicon',
  },
  question: {path: 'assets/icons/question.svg', name: 'question'},
  comingSoon: {path: 'assets/icons/coming-soon.svg', name: 'coming-soon'},
  underMaintenance: {
    path: 'assets/icons/under-maintenance.svg',
    name: 'under-maintenance',
  },
  circleTick: {path: 'assets/icons/circle-tick.svg', name: 'circle-tick'},
  tickFill: {path: 'assets/icons/tick-fill.svg', name: 'tick-fill'},
  leaf: {path: 'assets/icons/leaf.svg', name: 'leaf'},
  copy: {path: 'assets/icons/copy.svg', name: 'copy'},
  calender: {path: 'assets/icons/calender.svg', name: 'calender'},
  circleAdd: {path: 'assets/icons/circle-add.svg', name: 'circle-add'},
};

export const registeredIcons = () => {
  return Object.values(paths);
};
