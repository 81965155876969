import {DateRange} from 'src/app/app.models';

export const formatNthDate = value => {
  return {
    date: value.getDate(),
    nth: nth(value.getDate()),
    month: monthsObj[value.getMonth()],
    year: value.getFullYear(),
  };
};

export const monthsObj = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

const nth = d => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getTime = (date: string | Date) => {
  return new Date(date).getTime();
};

export const dateCompare = (d1: string, d2: string) => {
  return new Date(d1).getTime() - new Date(d2).getTime();
};

export const getDates = (startDate: Date, stopDate: Date): Date[] => {
  let date = new Date(startDate);
  let dateArray = [];
  while (date.getTime() <= stopDate.getTime()) {
    dateArray.push(date);
    date = new Date(date.setDate(date.getDate() + 1));
  }
  return dateArray;
};

export const startOfMonth = (date: Date) => {
  const d = date || new Date();
  return new Date(d.getFullYear(), d.getMonth(), 1);
};

export const endOfMonth = (date: Date) => {
  const d = date || new Date();
  return new Date(d.getFullYear(), d.getMonth() + 1, 0);
};

export const getFutureDate = (days: number, date = new Date()) => {
  date = new Date(date);
  return new Date(date.setDate(date.getDate() + days));
};

export const getPastDate = (days: number, date = new Date()) => {
  date = new Date(date);
  return new Date(date.setDate(date.getDate() - days));
};

export const isInBetween = (date: Date, range: DateRange) => {
  const {start, end} = range;
  if (!(start && end)) return false;
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
};

export const today = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );
};
