import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getLAorDERole} from 'src/app/home/actions/store/actions.helper';
import {AuthState} from './auth.models';
import * as helper from './auth.helper';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const isLoggedIn = createSelector(selectAuthState, auth => !!auth.user);
export const isMobile = createSelector(selectAuthState, auth => auth.isMobile);
export const isTab = createSelector(selectAuthState, auth => auth.isTab);
export const isWindows = createSelector(
  selectAuthState,
  auth => auth.isWindows,
);
export const devices = createSelector(selectAuthState, auth => {
  const {isMobile, isTab, isWindows} = auth;
  return {isTab, isMobile, isWindows};
});
export const snacks = createSelector(selectAuthState, auth => auth.snacks);
export const token = createSelector(selectAuthState, auth => {
  return auth.user ? auth.user.token : '';
});
export const user = createSelector(selectAuthState, auth => auth.user);
export const userMail = createSelector(
  selectAuthState,
  auth => auth.user.email,
);

export const isUsageSummaryEligible = createSelector(selectAuthState, auth => {
  return (
    auth.user.personNumber === 123507 ||
    auth.user.personNumber === 117221 ||
    auth.user.personNumber === 113406 ||
    auth.user.personNumber === 111776
  );
});

export const featureRoles = createSelector(selectAuthState, auth => {
  return auth.user.featureRoleData.filter(role => role.status !== 1);
});

export const allProjectFeatureRoles = createSelector(selectAuthState, auth => {
  return auth.user.featureRoleData;
});

export const allProjectRiskRoles = createSelector(selectAuthState, auth => {
  return helper.getRiskRole(auth.user.featureRoleData);
});

export const badgeInfo = createSelector(selectAuthState, state => {
  return state.badgeInfo;
});
export const error = createSelector(selectAuthState, state => {
  return state.error;
});

export const prjLAorDE = createSelector(selectAuthState, auth => {
  return getLAorDERole(auth.user.featureRoleData);
});

export const isCPfor1Prj = createSelector(featureRoles, roles => {
  return roles.some(item => item.roles[3]);
});

export const cpCodexIds = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.forEach(item => {
    if (item.roles[3]) ref[item.codexId] = true;
  });
  return ref;
});

export const notAccActionCodexIds = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.forEach(item => {
    if (!item.roles[8] || !item.roles[11] || !item.roles[22])
      ref[item.codexId] = true;
  });
  return ref;
});

export const cpIds = createSelector(featureRoles, roles => {
  let cpCodexId: number[] = [];
  roles.forEach(item => {
    if (item.roles[3]) {
      cpCodexId.push(item.codexId);
    }
  });
  return cpCodexId;
});

export const DeOrLAIds = createSelector(featureRoles, roles => {
  let DeOrLACodexId: number[] = [];
  roles.forEach(item => {
    if (item.roles[8] || item.roles[11]) {
      DeOrLACodexId.push(item.codexId);
    }
  });
  return DeOrLACodexId;
});

export const rcaProjectElegibleIds = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.forEach(item => {
    if (item.roles[1] || item.roles[7] || item.roles[2]) {
      ref[item.codexId] = true;
    }
  });
  return ref;
});

export const rcaAccountElegibleIds = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.filter(item => {
    if (item.roles[26] || +item.roles[2]) ref[item.codexId] = true;
  });
  return ref;
});

export const isLAorDE = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.filter(item => {
    if (item.roles[8] || +item.roles[11]) ref[item.codexId] = true;
  });
  return ref;
});

export const isDPorDM = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.filter(item => {
    if (item.roles[2] || +item.roles[26]) ref[item.codexId] = true;
  });
  return ref;
});

export const isDM = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.filter(item => {
    if (item.roles[2]) ref[item.codexId] = true;
  });
  return ref;
});

export const isDP = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.filter(item => {
    if (item.roles[26]) ref[item.codexId] = true;
  });
  return ref;
});

export const mvhCid = createSelector(featureRoles, roles => {
  let ref: {[s: string]: boolean} = {};
  roles.filter(item => {
    if (item.roles[29]) ref[item.codexId] = true;
  });
  return ref;
});

export const isMVH = (codexId: number) =>
  createSelector(featureRoles, roles => {
    return roles
      .find(a => a.codexId === codexId)
      .rolefeaturelist.every(a => a.roleId == 29);
    // .rolefeaturelist.every(p => p.roleId == 29);
  });

export const isAllLAorDE = createSelector(featureRoles, roles => {
  return roles.every(p =>
    p.rolefeaturelist.some(p => p.roleId === 8 || p.roleId === 11),
  );
});
export const escAccess = createSelector(allProjectFeatureRoles, roles => {
  let ref: {[s: string]: number[]} = {};

  roles.forEach(role => {
    const featureListSet = new Set<number>();
    role.rolefeaturelist.forEach(roleFeatureList => {
      roleFeatureList.featurelist.forEach(feature => {
        featureListSet.add(feature);
      });
    });
    ref[role.codexId] = Array.from(featureListSet);
  });

  return ref;
});
