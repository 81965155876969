import * as XLSX from 'xlsx';

export const JSONToExcel = (jsonData, config: ExcelConfigData) => {
  const {sheetName, fileName, columns} = config;
  let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData, {
    cellDates: true,
  });
  XLSX.utils.sheet_add_aoa(ws, [columns], {origin: 'A1'});
  // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
  // ws['!cols'] = [{wch: 20}];
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, fileName + '.xlsx');
};

export interface ExcelConfigData {
  sheetName: string;
  fileName: string;
  columns?: string[];
}
