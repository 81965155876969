import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {colors} from 'src/app/auth/store';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  colors$ = this.store.select(colors);
  constructor(private store: Store, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {}
}
