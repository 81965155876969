import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {pages} from '../app.pages';
import {AuthComponent} from './auth.component';

const routes: Routes = [{path: pages.AUTH.path, component: AuthComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
