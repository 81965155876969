import {PulseIndicators, StatusIndicators} from 'src/app/app.models';
import {FilterItem, IdValue, Indicators} from 'src/app/app.models';
import {pages} from 'src/app/app.pages';
import {appStrings} from 'src/app/library/constants/strings';
import {environment} from 'src/environments/environment.prod';

export const makeUrl = (paths: string[], params?: string[]) => {
  paths = [pages.HOME.path, ...paths];
  let url = paths.reduce((pv, cv) => `${pv}/${cv}`, '');
  if (!params) return url;
  const param = params.reduce((pv, cv) => (pv ? `${pv}&${cv}` : cv), '');
  return `${url}?${param}`;
};

export const handleErr = err => {
  if (typeof err === 'string') return err;
  if (!environment.production) {
    if (err?.error?.errorMessage) return err.error.errorMessage;
    if (err?.message) return err.message;
  }
  return appStrings.error;
};

export const indication = (indicationNumber: number) => {
  switch (indicationNumber) {
    case 2:
      return Indicators.RED;
    case 1:
      return Indicators.AMBER;
    case 0:
      return Indicators.GREEN;
    default:
      return Indicators.GRAY;
  }
};

export const pulseIndication = (indicationNumber: number) => {
  switch (indicationNumber) {
    case 2:
      return PulseIndicators.RED;
    case 1:
      return PulseIndicators.AMBER;
    case 0:
      return PulseIndicators.GREEN;
    default:
      return PulseIndicators.GRAY;
  }
};

export const statusIndication = (statusNumber: number) => {
  switch (statusNumber) {
    case 2:
      return StatusIndicators.CLOSED;
    case 1:
      return StatusIndicators.INPROGRESS;
    case 0:
      return StatusIndicators.OPEN;
    default:
      return StatusIndicators.OPEN;
  }
};

export const statusindicator = {
  0: StatusIndicators.OPEN,
  1: StatusIndicators.INPROGRESS,
  2: StatusIndicators.CLOSED,
};
export const statusindicatorRevers = {
  Open: 0,
  'In Progress': 1,
  Closed: 2,
};

export const indicators = {
  0: Indicators.GREEN,
  1: Indicators.AMBER,
  2: Indicators.RED,
  3: Indicators.GRAY,
};

export const pulseReducerIndicators = {
  0: PulseIndicators.GREEN,
  1: PulseIndicators.AMBER,
  2: PulseIndicators.RED,
  3: PulseIndicators.GRAY,
};

export const healthIndicators = {
  0: 'Green',
  1: 'Amber',
  2: 'Red',
  3: 'Grey',
};

export const indicatorsRev = {
  red: 2,
  amber: 1,
  green: 0,
};

export const typeIndication = (type: string) => {
  switch (type) {
    case 'action':
      return 'Action';
    case 'issue':
      return 'Internal Issue';
    case 'risk':
      return 'Risk';
    default:
      return 'Unknown Type';
  }
};

export const dropdownHelper = (arr: any[], key?: string, idKey?: string) => {
  let ref = {};
  const data: IdValue[] = [];
  arr.forEach((item, i) => {
    if (idKey) {
      if (!ref[item[key]]) {
        ref[item[key]] = true;
        data.push({id: `${item[idKey]}`, value: item[key]});
      }
    } else if (key) {
      if (!ref[item[key]]) {
        ref[item[key]] = true;
        data.push({id: idKey ? item[idKey] : `${i}`, value: item[key]});
      }
    } else {
      if (!ref[item]) {
        ref[item] = true;
        data.push({id: `${i}`, value: item});
      }
    }
  });
  return data;
};

export const getValueStr = (
  data: FilterItem[],
  separator = '',
  key = 'value',
) => {
  return data
    .filter(sv => sv.isChecked)
    .map(v => v[key])
    .join(separator);
};

export const dismissHandler = (data: any[], ref: any) => {
  return data.map(v => {
    if (!ref[v.value]) return v;
    return {...v, isChecked: ref[v.value] === '1'};
  });
};

export const replaceText = (str: string, replaceValue: string) => {
  return str.replace('{#{#}#}', replaceValue);
};

export const createRefBool = (arr: any[], key: string) => {
  let ref = {};
  arr.forEach(item => {
    if (!ref[item[key]]) ref[item[key]] = true;
  });
  return ref;
};

export const camelCase = (str: string) => {
  return str[0].toUpperCase() + str.substring(1);
};

export const base64ToFile = ([fileName, dataURI]) => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array]);
  return new File([blob], fileName);
};

export const compare = (str1: string, str2: string) => {
  return str1.toLowerCase().includes(str2);
};

export const lastArrElement = (arr: any[]) => {
  if (arr.length === 0) return null;
  return arr[arr.length - 1];
};

export * from './encryption';
export * from './excel';
export * from './date.helper';
