<div
  [class.backdrop]="!!backdrop"
  [class.pointer]="isPointer"
  [ngStyle]="ngStyle$ | async"
  [style.borderColor]="borderColor$ | async"
  [style.fillOpacity]="opacity"
>
  <div
    class="flex"
    [class.disabled]="disabled"
    [style.transform]="rotation"
    [ngStyle]="{
      height: height || (iconSize$ | async) + 'px',
      width: width || (iconSize$ | async) + 'px'
    }"
  >
    <mat-icon
      *ngIf="isPointer; else onlyIcon"
      matRipple
      [matRippleCentered]="true"
      [matRippleUnbounded]="true"
      [matRippleRadius]="iconSize$ | async"
      [matRippleDisabled]="disabled"
      [ngStyle]="{
        height: height || (iconSize$ | async) + 'px',
        width: width || (iconSize$ | async) + 'px',
        display: 'flex',
        stroke: color,
        fill: color
      }"
      [svgIcon]="name"
      (click)="!disabled && iconClick.emit()"
    ></mat-icon>
    <ng-template #onlyIcon>
      <mat-icon
        [ngStyle]="{
          height: height || (iconSize$ | async) + 'px',
          width: width || (iconSize$ | async) + 'px',
          display: 'flex',
          stroke: color,
          fill: color
        }"
        [svgIcon]="name"
      ></mat-icon>
    </ng-template>
  </div>
</div>
