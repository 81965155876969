import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {map} from 'rxjs';
import {colors, colorsCSS, isWindows} from 'src/app/auth/store';

import {paths} from './icons.paths';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
})
export class IconsComponent {
  @Input() name = 'add';
  @Input() color = '#000';
  @Input() size = 24;
  @Input() rotate = 0;
  @Input() height: string;
  @Input() width: string;
  @Input() isPointer = false;
  @Input() disabled = false;
  @Input() backdrop: 'primary' | 'secondary';
  @Input() opacity = 1;

  @Output() iconClick = new EventEmitter();

  isWindows$ = this.store.select(isWindows);
  colorCSS$ = this.store.select(colorsCSS);
  colors$ = this.store.select(colors);

  get rotation() {
    return `rotate(${this.rotate}deg)`;
  }

  get iconSize$() {
    return this.isWindows$.pipe(
      map(isWindows => (isWindows ? this.size - 4 : this.size)),
    );
  }

  get borderColor$() {
    return this.colors$.pipe(
      map(colors => {
        if (this.backdrop !== 'primary') return this.color;
        return colors.primary;
      }),
    );
  }

  get ngStyle$() {
    return this.colorCSS$.pipe(
      map(colorCSS => {
        if (this.backdrop === 'primary') return colorCSS.bgP;
        return {};
      }),
    );
  }

  constructor(
    private store: Store,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      paths.add.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.add.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.menu.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.menu.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.remove.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.remove.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.cardView.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.cardView.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.listView.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.listView.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.eva.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.eva.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.bell.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.bell.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.date.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.date.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.alert.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.alert.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.search.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.search.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.filter.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.filter.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.uncheck.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.uncheck.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.check.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.check.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.target.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.target.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.happy.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.happy.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.okay.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.okay.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.sad.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.sad.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.risk.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.risk.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.assumption.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.assumption.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.circleAdd.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.circleAdd.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.internalIssue.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.internalIssue.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.comingSoon.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.comingSoon.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.question.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.question.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.dependency.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.dependency.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.escalations.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.escalations.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.action.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.action.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.clientPulse.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.clientPulse.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.statusReport.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.statusReport.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.collaps.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.collaps.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.customerEmail.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.customerEmail.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.riskProfile.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.riskProfile.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.Assessment.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.Assessment.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.projectGovernance.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        paths.projectGovernance.path,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      paths.revenueMargin.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.revenueMargin.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.leaf.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.leaf.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.folder.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.folder.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.circleTick.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.circleTick.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.projectInfo.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.projectInfo.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.stakeholder.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.stakeholder.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.projectConfig.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.projectConfig.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.projectPlan.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.projectPlan.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.projectAssets.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.projectAssets.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.health.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.health.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.brillio.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.brillio.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.goTo.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.goTo.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.person.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.person.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.copy.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.copy.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.uploadFile.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.uploadFile.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.more.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.more.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.rca.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.rca.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.edit.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.edit.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.close.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.close.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.arrowDown.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.arrowDown.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.codex.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.codex.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.profile.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.profile.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.minus.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.minus.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.task.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.task.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.ribbon.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.ribbon.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.arrowUp.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.arrowUp.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.ribbon1.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.ribbon1.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.tickFill.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.tickFill.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.ribbon2.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.ribbon2.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.rightArrow.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.rightArrow.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.comment.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.comment.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.delete.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.delete.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.tick.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.tick.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.email.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.email.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.reload.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.reload.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.download.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.download.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.file.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.file.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.riskprofileicon.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        paths.riskprofileicon.path,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      paths.rightarrowicon.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        paths.rightarrowicon.path,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      paths.tripleArrow.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.tripleArrow.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.projectSearch.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.projectSearch.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.calender.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.calender.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.circleAdd.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.circleAdd.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.arrowRight.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(paths.arrowRight.path),
    );
    this.matIconRegistry.addSvgIcon(
      paths.underMaintenance.name,
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        paths.underMaintenance.path,
      ),
    );
  }
}
