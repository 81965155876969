export const pages = {
  AUTH: {path: 'auth', name: 'Auth'},
  HOME: {path: 'home', name: 'Home'},
  WELCOME: {path: 'welcome', name: 'Welcome to CODEX'},
  MY_PROJECTS: {path: 'my-projects', name: 'My Projects'},
  PROJECT_DETAILS: {path: 'project-details', name: 'Project Details'},
  TASKS_TO_COMPLETE: {path: 'tasks-to-complete', name: 'Tasks to Complete'},
  WHATS_NEW: {path: 'whats-new', name: 'Whats New'},
  ACTIONS: {path: 'actions', name: 'All Actions'},
  CODEX_DASHBOARD: {path: 'codex-dashboard', name: ''},
  HELP_DESK: {path: 'help-desk', name: ''},
  LINK_PROJECT: {path: 'link-project', name: 'Link Project'},
  RIGHT_START: {path: 'right-start', name: 'Right Start'},
  DOCS: {path: 'docs', name: ''},
  INTRODUCTION: {path: 'introduction', name: ''},
  BUTTON: {path: 'button', name: ''},
  LINK: {path: 'link', name: ''},
  ICON: {path: 'icon', name: ''},
  ESCALATION: {path: 'escalation', name: 'All Escalations/Early Warnings'},
  INTERNAL_ISSUE: {path: 'internalissue', name: 'All Internal Issues'},
  RISK_MANAGEMENT: {path: 'risk-management', name: 'All Risks'},
  STAKEHOLDER: {path: 'stakeholder', name: 'Stakeholder'},
  LINK_PROJECT_FORM: {path: 'form', name: 'Create Project'},
  DEPENDENCY: {path: 'dependency', name: 'Dependency'},
  ASSUMPTION: {path: 'assumption', name: 'Assumption'},
  STATUS_REPORT: {path: 'status-report', name: 'Status Report'},
  PROJECT_HEALTH: {path: 'project-health', name: 'Project Health'},
  PROJECT_HEALTH_HISTORY: {
    path: 'project-health-history',
    name: 'Project Health History',
  },
  SEARCH: {path: 'search', name: 'Search'},
  CUSTOMER_MAIL: {path: 'customer-mail', name: 'Customer Mail'},
  PROJECT_CONFIGURATION: {
    path: 'project-configuration',
    name: 'Project Configuration',
  },
  STATUS_REPORT_FORM: {path: 'form', name: 'Create Status Report'},
  RISK_PROFILE: {path: 'risk-profile', name: 'Risk Profile'},
  CUSTOMER_MAIL_FORM: {path: 'form', name: 'Create Customer Emails'},
  INTEGRATED_PROJECT_PLAN: {
    path: 'integrated-project-plan',
    name: 'Integrated Project Plan',
  },
  PROJECT_INFORMATION: {
    path: 'project-information',
    name: 'Project Information',
  },
  PROJECT_GOVERNANCE: {path: 'project-governance', name: 'Project Governance'},
  CLIENT_PULSE: {path: 'client-pulse', name: 'Client Pulse'},
  CUSTOMER_SENTIMENTS: {
    path: 'customer-sentiments',
    name: 'Customer Sentiments',
  },
  TELEMETRY: {path: 'telemetry', name: 'Telemetry'},
  ASSESSMENT: {path: 'assessment', name: 'Project Self-assessment'},
  ASSESSMENT_FORM: {path: 'form', name: 'New Assessment'},
  CX_SURVEY_FORM: {path: 'form', name: 'New CX Survey'},
  CX_SURVEY_SUB_FORM1: {
    path: 'email-template',
    name: 'New CX Survey Email Template',
  },
  CX_SURVEY_SUB_FORM2: {
    path: 'email-preview',
    name: 'New CX Survey Email Preview',
  },
  CX_SURVEY_SUB_FORM3: {
    path: 'feedback-preview',
    name: 'New CX Survey feedback Preview',
  },
  FEEDBACK: {path: 'feedback', name: 'Feedback'},
  SURVEY_FEEDBACK: {path: 'survey-feedback', name: 'Survey Feedback'},
  FEEDBACK_THANK_YOU: {path: 'thank-you', name: 'Thank You'},
  PROJECT_ASSETS: {path: 'project-assets', name: 'Project Assets'},
  PROJECT_HH: {path: 'project-health-view', name: 'Project Health View'},
  MY_ACCOUNTS: {path: 'my-accounts', name: 'My Accounts'},
  ACCOUNT_DETAILS: {path: 'account-details', name: 'Account Details'},
  ACCOUNT_INFORMATION: {
    path: 'account-information',
    name: 'Account Information',
  },
  ASSETS: {path: 'assets', name: 'Assets'},
  REVENUE_MARGIN: {path: 'revenue-margin', name: 'Revenue Margin'},
  CX_SURVEY: {path: 'cx-survey', name: 'CX Survey'},
};

export interface Page {
  name: string;
  path: string;
}
