<div class="p-24 dialog-container relative">
  <div *ngIf="data.button1Name" class="icon-position">
    <app-icons
      [isPointer]="true"
      [mat-dialog-close]="data.dismissData || ''"
      name="remove"
      [size]="16"
      [color]="(colors$ | async).secondary3"
    ></app-icons>
  </div>
  <app-icons
    name="alert"
    [size]="80"
    [opacity]="0.33"
    [color]="(colors$ | async).primary"
  ></app-icons>
  <p class="p-24 font-20-b text-center">{{ data.message }}</p>
  <div class="flex w-100 justify-around">
    <app-button
      *ngIf="data.button2Name"
      [name]="data.button2Name"
      [design]="data.button2Type"
      [mat-dialog-close]="data.button2Name"
    ></app-button>
    <app-button *ngIf="data.button1Name"
      [name]="data.button1Name"
      [mat-dialog-close]="data.button1Name"
    ></app-button>
  </div>
  <div class="pt-8" *ngIf="data.button3Name">
    <app-link
      [title]="data.button3Name"
      [mat-dialog-close]="data.button3Name"
    ></app-link>
  </div>
</div>
