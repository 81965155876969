import {createAction, props} from '@ngrx/store';
import {Message} from 'src/app/app.models';
import {SnackbarData} from 'src/app/library/components/snackbar/snackbar.component';
import {BadgeInfoData, User} from './auth.models';
import {Role} from './auth.service';

export enum types {
  LOGIN = '[Auth] Login',

  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',
  LOGOUT_ERR = '[Auth] Logout Error',

  AUTO_LOGIN = '[Auth] Auto Login',

  SET_DEVIDE_WIDTH = '[App] Set Device Width',
  SET_TOKEN = '[Auth] Set Access Token',

  GET_ACCOUNT_ROLES = '[Auth] Get Account Roles',
  SET_ACCOUNT_ROLES = '[Auth] Set Account Roles',
  SET_ACCOUNT_ROLES_ERR = '[Auth] Set Account Roles Error',

  GET_USER_DETAILS = '[Auth] Get User Details',
  SET_USER_DETAILS = '[Auth] Set User Details',
  SET_USER_DETAILS_ERR = '[Auth] Set User Details Error',

  FETCH_CODEX_IDS = '[Auth] Fetch Codex Ids',
  SET_CODEX_IDS = '[Auth] Set Codex Ids',
  SET_CODEX_IDS_ERR = '[Auth] Set Codex Ids Err',

  ADD_SNACK_ITEM = '[Auth] Add Snack Item',
  REMOVE_SNACK_ITEM = '[Auth] Remove Snack Item',

  FETCH_BADGE_INFO = '[My Projects] Fetch Badge Info',
  SET_BADGE_INFO = '[My Projects] Set Badge Info',
  SET_BADGE_INFO_ERR = '[My Projects] Set Badge Info Err',
}

export const setDeviceWidth = createAction(
  types.SET_DEVIDE_WIDTH,
  props<{payload: number}>(),
);

export const setToken = createAction(
  types.SET_TOKEN,
  props<{payload: string}>(),
);

export const fetchAccountRoles = createAction(
  types.GET_ACCOUNT_ROLES,
  props<{payload: string}>(),
);
export const setAccountRoles = createAction(
  types.SET_ACCOUNT_ROLES,
  props<{payload: Role[]}>(),
);
export const fetchAccountRolesErr = createAction(
  types.SET_ACCOUNT_ROLES_ERR,
  props<{payload: Message}>(),
);

export const login = createAction(types.LOGIN);

export const logout = createAction(types.LOGOUT);

export const logoutSuccess = createAction(
  types.LOGOUT_SUCCESS,
  props<{payload: string}>(),
);

export const logoutErr = createAction(
  types.LOGOUT_ERR,
  props<{payload: string}>(),
);

export const autoLogin = createAction(types.AUTO_LOGIN);

export const getUserDetails = createAction(types.GET_USER_DETAILS);
export const setUserDetails = createAction(
  types.SET_USER_DETAILS,
  props<{payload: User}>(),
);
export const setUserDetailsErr = createAction(
  types.SET_USER_DETAILS_ERR,
  props<{payload: string}>(),
);

export const fetchCodexIds = createAction(
  types.FETCH_CODEX_IDS,
  props<{payload: User}>(),
);
export const setCodexIds = createAction(
  types.SET_CODEX_IDS,
  props<{payload: number[]}>(),
);
export const setCodexIdsErr = createAction(
  types.SET_CODEX_IDS_ERR,
  props<{payload: string}>(),
);

export const addSnackItem = createAction(
  types.ADD_SNACK_ITEM,
  props<{payload: SnackbarData}>(),
);
export const removeSnackItem = createAction(
  types.REMOVE_SNACK_ITEM,
  props<{payload: string}>(),
);

export const fetchBadgeInfo = createAction(types.FETCH_BADGE_INFO);
export const setBadgeInfo = createAction(
  types.SET_BADGE_INFO,
  props<{payload: BadgeInfoData}>(),
);
export const setBadgeInfoErr = createAction(
  types.SET_BADGE_INFO_ERR,
  props<{payload: string}>(),
);
