import {Colors, User, Roles} from './auth.models';

export const userDefault: User = {
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  name: '',
  refreshToken: '',
  token: '',
  personNumber: null,
  isLeader: false,
  codexIds: [],
  featureRoleData: [],
  activeIds: [],
};

export const defaultColors: Colors = {
  primary: '#00af98',
  primaryCompliment: '#FFFFFF',
  primaryCompliment1: '#FFFFFFA1',
  primary1: '#75d4c7',
  primary2: '#c4ede7',
  primary3: '#f1ffff',
  primary4: '#51B1A3',
  primary5: '#F4FFFF',
  primary6: '#E8FEFF9E',
  gradient: 'linear-gradient(90deg, #00af98 3.28%, #75d4c7 78.93%)',
  secondary: '#0a1729',
  secondary1: '#47586f',
  secondary2: '#667488',
  secondary3: '#afbed3',
  secondary4: '#e1e8f2',
  secondary5: '#f1f4f8',
  secondary6: '#f7f9fb',
  secondary7: '#FAFBFD',
  shimmer: 'linear-gradient(to right,#f1f4f8 4%,#e1e8f2 25%,#f1f4f8 36%)',
  red: '#fd6965',
  red1: '#D04F4C',
  red2: '#FD69654A',
  green: '#7ee5a8',
  green1: '#3EBB70',
  amber: '#f8c84f',
  amber1: '#CB991A',
  ripple: '#00af9833',
  rippleS: '#47586f33',
  rippleCompliment: '#ffffff33',
  cardSubBg: '#e1e8f23d',
};

export const roles = {
  1: 'Project Manager',
  2: 'Delivery Manager',
  3: 'Client Partner',
  4: 'Participating Practice Manager',
  5: 'Account Manager',
  6: 'Practice Head',
  7: 'Acting Project Manager',
  8: 'Delivery Excellence',
  9: 'User',
  10: 'Regional Head',
  11: 'Leadership',
  12: 'Admin',
  14: 'SPOC',
  15: 'Sponsor',
  16: 'Influencer',
};

export const statusReportRoleCheck = [1, 2, 7, 26];
