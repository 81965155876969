import {CodexFeatureRoleData} from './auth.models';

export const getRiskRole = (arr: CodexFeatureRoleData[]) => {
  const riskCodexIds = arr
    .filter(
      item =>
        item.rolefeaturelist.some(role => role.featurelist.includes(7)) ||
        Object.keys(item.roles).some(mvh => mvh === '29'),
    )
    .map(item => item.codexId);
  return riskCodexIds;
};
