import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  catchError,
  concatMap,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {pages} from 'src/app/app.pages';
import {appStrings} from 'src/app/library/constants/strings';
import {DialogService} from 'src/app/library/services/dialog.service';
import {encryptObj, decryptObj, replaceText} from 'src/Utils';
import * as authActions from './auth.action';
import {User} from './auth.models';
import {token, user} from './auth.selector';
import {AuthService, Role} from './auth.service';

@Injectable()
export class AuthEffect {
  autoLogin$ = createEffect(() => {
    return this.action$.pipe(
      ofType(authActions.autoLogin),
      mergeMap(() => {
        const user = decryptObj<User>(localStorage.getItem('codex-user'));
        const accountRoles = decryptObj<Role[]>(
          localStorage.getItem('user-account-roles'),
        );
        if (typeof user === 'string') return [{type: 'DUMMY'}];
        if (typeof accountRoles === 'string') return [{type: 'DUMMY'}];

        return [
          authActions.setUserDetails({payload: user}),
          authActions.setAccountRoles({payload: accountRoles}),
        ];
      }),
    );
  });

  getUserDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(authActions.setToken),
      switchMap(({payload}) => {
        return forkJoin([
          this.apis.fetchUserDetails(payload),
          this.apis.fetchUserRole(payload),
        ]).pipe(
          map(([res, isLeader]) => {
            const user: User = {...res, isLeader};
            return authActions.fetchCodexIds({payload: user});
          }),
          catchError(err => of(authActions.setUserDetailsErr({payload: err}))),
        );
      }),
    );
  });

  setAccountRole$ = createEffect(() => {
    return this.action$.pipe(
      ofType(authActions.setToken),
      switchMap(({payload}) => {
        return this.apis.fetchAccountRoles(payload, []).pipe(
          map(p => {
            localStorage.setItem('user-account-roles', encryptObj(p));
            return authActions.setAccountRoles({payload: p});
          }),
          catchError(err =>
            of(authActions.fetchAccountRolesErr({payload: err})),
          ),
        );
      }),
    );
  });

  fetchCodexIds$ = createEffect(() => {
    return this.action$.pipe(
      ofType(authActions.fetchCodexIds),
      switchMap(({payload}) => {
        return this.apis.fetchCodexIds(payload.token, payload.isLeader).pipe(
          map(({codexIds, data, activeIds}) => {
            const user: User = {
              ...payload,
              codexIds,
              featureRoleData: data,
              activeIds,
            };
            localStorage.setItem('codex-user', encryptObj(user));
            localStorage.setItem('User-Id', user.personNumber.toString());
            return authActions.setUserDetails({payload: user});
          }),
          catchError(err => of(authActions.setCodexIdsErr({payload: err}))),
        );
      }),
    );
  });

  authSuccess$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(authActions.setUserDetails),
        tap(() => {
          const path = location.pathname;
          if (!(path.includes(pages.AUTH.path) || path === '/')) return;
          if (localStorage.getItem('isNew')) {
            this.router.navigateByUrl(`/${pages.HOME.path}`);
          } else {
            this.router.navigateByUrl(`/${pages.WELCOME.path}`);
            localStorage.setItem('isNew', 'loggedIn');
          }
        }),
      );
    },
    {dispatch: false},
  );

  logout$ = createEffect(() => {
    const {logout} = appStrings.auth;
    return this.action$.pipe(
      ofType(authActions.logout),
      concatMap(action => {
        return of(action).pipe(withLatestFrom(this.store.select(user)));
      }),
      switchMap(([_, user]) => {
        this.dialog.loading(replaceText(logout, user.name));
      return this.apis.logout(user.token).pipe(
          map(payload => authActions.logoutSuccess({payload})),
          catchError(payload => of(authActions.logoutErr({payload}))),
        );
      }),
    );
  });

  logoutErr$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(authActions.logoutErr),
        tap(({payload}) => {
          this.dialog.error({message: payload, button1Name: 'Close'});
        }),
      );
    },
    {dispatch: false},
  );

  logoutSuccess$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(authActions.logoutSuccess),
        tap(({payload}) => {
          localStorage.removeItem('codex-user');
          localStorage.removeItem('user-account-roles');
          window.open(payload, '_self');
        }),
      );
    },
    {dispatch: false},
  );

  fetchBadgeInfo$ = createEffect(() => {
    return this.action$.pipe(
      ofType(authActions.fetchBadgeInfo),
      concatMap(action => {
        return of(action).pipe(withLatestFrom(this.store.select(token)));
      }),
      switchMap(([_, token]) => {
        return this.apis.fetchBadgeInfo(token).pipe(
          map(payload => authActions.setBadgeInfo({payload})),
          catchError(payload => of(authActions.setBadgeInfoErr({payload}))),
        );
      }),
    );
  });

  constructor(
    private action$: Actions,
    private apis: AuthService,
    private router: Router,
    private dialog: DialogService,
    private store: Store,
  ) {}
}
