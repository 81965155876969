<div
  class="link"
  [class.pointer]="!disabled"
  [class.not-allowed]="disabled"
  [class.disabled]="disabled"
  matRipple
  [matRippleColor]="(colors$ | async).ripple"
  (click)="handleClick()"
>
  <app-icons
    *ngIf="iconName"
    [size]="iconSize"
    [color]="(colors$ | async).primary"
    [name]="iconName"
  ></app-icons>
  <p
    [ngClass]="[font]"
    [class.pl-8]="iconName"
    [ngStyle]="{color: (colors$ | async).primary}"
    rel="noopener noreferrer"
  >
    {{ title }}
  </p>
</div>
