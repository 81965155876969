import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MetaService {
  constructor(private meta: Meta, private title: Title) {}

  addPageDetails(data: PageData) {
    this.title.setTitle(data.title);
    this.meta.addTag({name: 'description', content: data.message});
  }

  private projectFilteredCount = new BehaviorSubject(0);
  currentFilteredCount = this.projectFilteredCount.asObservable();
  changeFilteredCount(message: number) {
    this.projectFilteredCount.next(message);
  }
}

export interface PageData {
  title: string;
  message: string;
}
