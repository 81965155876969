<div class="flex column flex-center justify-center">
  <span class="loader" [style.borderColor]="(colors$ | async).primary"></span>
  <p
    ngClass="font-20-sb"
    [style.color]="(colors$ | async).secondary1"
    *ngIf="data.message"
    class="text-center pt-16"
  >
    {{ data.message }}
  </p>
</div>
