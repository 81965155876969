<div class="p-24 dialog-container relative">
  <div class="icon-position">
    <app-icons
      [isPointer]="true"
      mat-dialog-close
      name="remove"
      [size]="16"
      [color]="(colors$ | async).secondary3"
    ></app-icons>
  </div>
  <app-icons
    name="{{
      data.title === 'codeXDashboard' ? 'under-maintenance' : 'coming-soon'
    }}"
    height="150px"
    width="200px"
    [color]="(colors$ | async).primary"
  ></app-icons>
  <p class="font-28-b pb-12 text-center" *ngIf="data.heading.length > 0">
    {{ data.heading }}
  </p>
  <p class="font-16 pb-24 text-center" *ngIf="data.heading.length > 0">
    {{ data.subtext }}
  </p>
  <div class="pt-8">
    <app-link
      *ngIf="data.title"
      title="Click Here"
      url="{{ data.url }}"
      target="_blank"
    >
    </app-link>
    <app-link
      *ngIf="!data.title"
      title="Okay, Got it!"
      mat-dialog-close
    ></app-link>
  </div>
</div>
