import { Injectable } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';

import { ComponentType } from '@angular/cdk/portal';
import { ErrorComponent, ErrorData } from '../dialogs/error/error.component';
import { LoadingComponent } from '../dialogs/loading/loading.component';
import { ComingSoonComponent } from '../dialogs/coming-soon/coming-soon.component';
import { appStrings } from '../constants/strings';
import { Message } from 'src/app/app.models';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog) { }

  open<T>(component: ComponentType<T>, config?: DialogConfig) {
    return this.dialog.open(component, {
      data: config?.data || null,
      disableClose: config?.disableClose || false,
      id: config?.id || null,
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      backdropClass: config?.isBlack ? 'black-backdrop' : 'white-backdrop',
      position: config?.position || null,
    });
  }

  close(id: string, data?) {
    this.dialog.getDialogById(id).close(data);
  }

  closeAll() {
    this.dialog.closeAll();
  }

  isDialogOpen(id: string) {
    return this.dialog.openDialogs.findIndex(d => d.id === id) !== -1;
  }

  openDialogs() {
    return this.dialog.openDialogs;
  }

  error(data: ErrorData, id = 'error') {
    return this.open(ErrorComponent, { data, id });
  }

  errorwWithNoClose(data: ErrorData, id = 'error') {
    return this.open(ErrorComponent, { data, id, disableClose: true });
  }

  maintenance() {
    return this.open(ErrorComponent, { data: appStrings.maintenance });
  }

  comingSoon(data: Message) {
    return this.open(ComingSoonComponent, { data });
  }

  loading(message?: string) {
    return this.dialog.open(LoadingComponent, {
      panelClass: 'transparent-dialog-container',
      backdropClass: 'white-backdrop',
      disableClose: true,
      data: { message },
    });
  }
}

export interface DialogConfig {
  data?: any;
  type?: any;
  disableClose?: boolean;
  position?: DialogPosition;
  id?: string;
  isBlack?: boolean;
}
