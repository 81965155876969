import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {pages} from './app.pages';
import {AuthGuard} from './auth/auth.guard';

const routes: Routes = [
  {
    path: pages.DOCS.path,
    loadChildren: () =>
      import('./documentation/documentation.module').then(
        m => m.DocumentationModule,
      ),
  },
  {
    path: pages.HOME.path,
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: pages.WELCOME.path,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./welcome/welcome.component').then(m => m.WelcomeComponent),
  },
  {
    path: pages.FEEDBACK.path,
    loadChildren: () =>
      import('./feedback/feedback.module').then(m => m.FeedbackModule),
  },
  {
    path: pages.SURVEY_FEEDBACK.path,
    loadChildren: () =>
      import('./survey-feedback/survey-feedback.module').then(
        m => m.SurveyFeedbackModule,
      ),

  },
  {path: '**', redirectTo: pages.AUTH.path},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
