import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs';
import {Apis} from 'src/app/app.apis';
import {HttpService} from 'src/app/library/services/http.service';
import {handleErr} from 'src/Utils';
import {BadgeInfoResponse, CodexFeatureRoleData, User} from './auth.models';

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private http: HttpService) {}

  fetchUserDetails(token: string) {
    return this.http
      .post<GetUserDetailsResponse>(Apis.getUserDetails, token, token)
      .pipe(
        map(res => {
          const {userId, userSessionObject: userObj} = res;
          const user: User = {
            personNumber: userId,
            email: userObj.mailId,
            firstName: userObj.givenName.split(' ')[0],
            lastName: userObj.familyName,
            middleName: '',
            name: userObj.givenName,
            refreshToken: '',
            token: userObj.authenticationToken,
            isLeader: false,
            codexIds: [],
            featureRoleData: [],
            activeIds: [],
          };
          return user;
        }),
        catchError(err => {
          throw handleErr(err);
        }),
      );
  }

  fetchUserRole(token: string) {
    return this.http.get<GetUserRole>(Apis.getUserRole, token).pipe(
      map(res => res.defaultRole === 'Leadership'),
      catchError(err => {
        throw handleErr(err);
      }),
    );
  }
  fetchAccountRoles(token: string, accountIds: string[]) {
    return this.http
      .post<Role[]>(Apis.getUserAccountWithRoleIds, token, accountIds)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          throw handleErr(err);
        }),
      );
  }

  fetchCodexIds(token: string, isLa: boolean) {
    return this.http
      .get<CodexFeatureRoleData[]>(Apis.getCodexIds, token, {isLa})
      .pipe(
        map(res => {
          return {
            codexIds: res.map(i => i.codexId),
            activeIds: res.filter(i => i.status !== 1).map(i => i.codexId),
            data: res.map(item => {
              let ref: {[s: string]: boolean} = {};
              item.rolefeaturelist.forEach(data => {
                if (!ref[data.roleId]) ref[data.roleId] = true;
              });
              return {...item, roles: ref};
            }),
          };
        }),
        catchError(err => {
          throw handleErr(err);
        }),
      );
  }

  logout(token: string) {
    return this.http.get<any>(Apis.logout, token).pipe(
      map(res => `${Apis.logoutRedirect}${location.origin}`),
      catchError(err => {
        throw handleErr(err);
      }),
    );
  }

  fetchBadgeInfo(token: string) {
    return this.http.get<BadgeInfoResponse>(Apis.getBadgeInfo, token).pipe(
      map(res => {
        if (res.status) return res.data;
        else throw res.message;
      }),
      catchError(err => {
        throw handleErr(err);
      }),
    );
  }
}

export interface GetUserDetailsResponse {
  userSessionObject: UserDetailsData;
  userId: number;
}

export interface GetUserRole {
  dashboardDefaultView: string;
  defaultRole: string;
}

export interface Role {
  accountId: string;
  roleIds: number[];
}

export interface UserDetailsData {
  id: number;
  authenticationToken: string;
  mailId: string;
  givenName: string;
  familyName: string;
  createdDate: number;
  updatedDate: number;
  feedsLastTimeViewed: number;
  lastActiveTime: number;
}
